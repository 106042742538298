<template>
  <el-dialog title="高级检索" width="800px" :close-on-click-modal="false" append-to-body @close="cancel()"
             :visible.sync="visible" v-if="visible">
    <el-form size="small" :model="inputForm" :inline="true" ref="inputForm" label-width="0px">
      <div class="m_b1" v-if="inputForm.whereList.length">筛选条件</div>
      <div v-for="(item, index) in inputForm.whereList" :key="index">
        <el-form-item :prop="`whereList.${index}.method`" v-if="methodType">
          <el-select v-model="item.method" placeholder="请选择" class="methodSel">
            <el-option v-for="(e, index) in methodOpt" :key="index"
                       :label="e.label"
                       :value="e.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`whereList.${index}.key`"
                      :rules="[{required: true, whitespace: true, message: '筛选的字段名不能为空', trigger: 'change'}]">
          <el-select v-model="item.key" placeholder="请选择要筛选的字段名" filterable @change="archivesChange(item)">
            <el-option v-for="c in config" :key="item.code" :label="c.basicName"
                       :value="c.code" :disabled="c.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`whereList.${index}.archivesBasicTypeMethodCode`"
                      :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'change'}]"
                      v-if="item.key">
          <el-select v-model="item.archivesBasicTypeMethodCode" placeholder="请选择">
            <el-option v-for="(e, index) in item.equalOpt" :key="index"
                       :label="e.archivesBasicTypeMethod"
                       :value="e.archivesBasicTypeMethodCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`whereList.${index}.value`"
                      :rules="[{required: true, message: '筛选值不能为空', trigger: item.archivesBasicMultiselectList.length ? 'change': 'blur'}]"
                      v-if="item.archivesBasicTypeMethodCode && item.archivesBasicTypeMethodCode != '=null' &&  item.archivesBasicTypeMethodCode != '!=null'">
          <!--字符-->
          <el-input class="valItem" v-model.trim="item.value" maxlength="300"
                    placeholder="请输入筛选值"
                    clearable
                    v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
          <!--数值、浮点数-->
          <el-input class="valItem" v-model.trim="item.value"
                    :maxlength="item.archivesBasicTypeCode == 'integer' ? '8' : '11'"
                    @input="getNumType(item)"
                    @blur="verifyNum(item)"
                    placeholder="请输入筛选值"
                    clearable
                    v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
          <!--下拉选择-->
          <el-select class="valItem" v-model="item.value"
                     :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                     placeholder="请选择筛选值" clearable
                     v-if="item.archivesBasicMultiselectList.length">
            <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                       :label="v.archivesBasicValue"
                       :value="v.archivesBasicValue"></el-option>
          </el-select>
          <!--时间-->
          <el-date-picker class="valItem" v-model="item.value" value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          type="datetime" clearable placeholder="请选择"
                          v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
          </el-date-picker>
          <el-date-picker class="valItem" v-model="item.value" value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd"
                          type="date" clearable placeholder="请选择"
                          v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
          </el-date-picker>
          <el-date-picker class="valItem" v-model="item.value" value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyyMMdd"
                          type="date" clearable placeholder="请选择"
                          v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
          </el-date-picker>
        </el-form-item>
        <div class="el-icon-error pointer" @click="del(index)"></div>
      </div>
      <el-button type="text" class="addCondition" @click="addCondition()">+ 添加筛选条件</el-button>

    </el-form>
    <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancel()">取消</el-button>
                <el-button size="small" type="primary" @click="confirm()" v-noMoreClick>确定</el-button>
            </span>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      visible: false,
      inputForm: {
        whereList: [],
      },
      config: [],
      methodType: '',
      methodOpt: [{
        value: 'and',
        label: '且'
      }, {
        value: 'or',
        label: '或'
      }]
    }
  },
  methods: {
    // type：recordData档案数据库检索，data典藏数据库检索
    // config：config.length=0时为全部
    init(config, advancedList, type) {
      this.methodType = type
      this.config = config
      this.inputForm.whereList = advancedList
      this.visible = true
      if (!advancedList.length) {
        this.addCondition()
      }
      if (type == 'recordData' || type == 'data') {
        if (!config.length) {
          this.getAllField()
        } else {
          this.getFixedField()
        }
      }
    },
    // 获取全部字段
    getAllField() {
      if (this.methodType == 'data') {
        // this.$axios(this.api.collection.archivesbasicSelectAllArchivesBasic).then(data => {
        //   if (data.status) {
        //     this.config = data.data.concat(this.config)
        //   }
        // })
        this.$axios(this.api.collection.archivesbasicSelectAllArchivesBasic).then(data1 => {
          this.$axios(this.api.collection.archivesbasicGetSysSelectArchivesBasic).then(data2 => {
            if (data1.status && data2.status) {
              this.config = data1.data.concat(this.config)
              this.config.push(...data2.data.concat(this.config))
            }
          })
        })
      }else {
        this.$axios(this.api.record.retrieveMetadataFromDatabaseRetrieval).then(data => {
          if (data.status) {
            this.config = data.data.concat(this.config)
          }
        })
      }
    },
    // 获取固定字段
    getFixedField() {
      if (this.methodType == 'data') {
        // this.$axios(this.api.collection.archivesbasicSelectAllArchivesBasic).then(data1 => {
        //   this.$axios(this.api.collection.archivesbasicGetSysSelectArchivesBasic).then(data2 => {
        //     if (data1.status && data2.status) {
        //       this.config = data1.data.concat(this.config)
        //       this.config.push(...data2.data.concat(this.config))
        //     }
        //   })
        // })
      }else {
        this.$axios(this.api.record.getSysSelectArchivesBasic).then(data => {
          if (data.status) {
            this.config = data.data.concat(this.config)
          }
        })
      }
    },
    // 选择档案分类
    changeArchives(node, item, index) {
      if (node) {
        this.$set(item, 'value', node)
        this.$refs['inputForm'].clearValidate([`whereList.${index}.value`]);
      }
    },
    // 选择字段名
    archivesChange(val) {
      this.config.map(item => {
        if (val.key == item.code) {
          this.$set(val, 'basicSys', item.basicSys)
          this.$set(val, 'equalOpt', item.archivesBasicTypeMethodList)
          this.$set(val, 'archivesBasicTypeCode', item.archivesBasicTypeCode)
          this.$set(val, 'archivesBasicMultiselectList', item.archivesBasicMultiselectList)
          this.$set(val, 'dataFormat', item.dataFormat)
          this.$set(val, 'value', '')
          this.$set(val, 'archivesBasicTypeMethodCode', '')
          if (item.archivesBasicMultiselectList && item.archivesBasicMultiselectList.length && item.archivesBasicMultiselectList[0].selectBy == 1) {
            this.$set(val, 'value', [])
          }
        }
      })
    },
    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(item, 'value', item.value.replace(regular, ''))
    },
    // 校验浮点数
    verifyNum(item) {
      if (item.archivesBasicTypeCode == "double" && item.value && !this.validate.isPrice(item.value)) {
        this.$set(item, 'value', (item.value * 1).toFixed(2))
      }
    },
    // 添加筛选条件
    addCondition() {
      this.inputForm.whereList.push({
        key: '',
        archivesBasicTypeMethodCode: "",
        archivesBasicTypeCode: "",
        value: '',
        method: 'and',
      })
    },
    // 删除筛选条件
    del(index) {
      if (this.inputForm.whereList.length == 1) {
        this.$message.warning('至少保留一个筛选项')
        return
      }
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.inputForm.whereList.splice(index, 1);
      })
    },
    // 取消
    cancel() {
      this.$emit("getDataList", {
        whereList: [], advancedList: []
      })
      this.visible = false
    },
    // 确定
    confirm() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          let advancedList = JSON.parse(JSON.stringify(this.inputForm.whereList))
          let whereList = [], fieldWhereList = []
          advancedList.map(item => {
            if (Array.isArray(item.value) && item.value.length) {
              item.value.map(v => {
                whereList.push({
                  key: item.key,
                  method: item.method,
                  archivesBasicTypeMethodCode: item.archivesBasicTypeMethodCode,
                  archivesBasicTypeCode: item.archivesBasicTypeCode,
                  value: v,
                })
              })
            } else {
              let obj = {
                key: item.key,
                method: item.method,
                archivesBasicTypeMethodCode: item.archivesBasicTypeMethodCode,
                archivesBasicTypeCode: item.archivesBasicTypeCode,
                value: item.value,
              }
              if (item.basicSys == 1 && this.methodType == 'recordData') {
                fieldWhereList.push(obj)
              } else {
                whereList.push(obj)
              }
            }
          })
          this.$nextTick(() => {
            this.$emit("getDataList", {
              fieldWhereList, whereList, advancedList
            })
            this.visible = false
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.el-icon-error {
  vertical-align: sub;
}

.valItem {
  width: 210px !important;
}

.methodSel {
  width: 60px !important;
}
</style>
