<template>
    <el-dialog title="选择档案" class="dialogTable" :close-on-click-modal="false" :fullscreen="true" append-to-body
               :visible.sync="visible" v-if="visible">
        <el-form size="small" :inline="true" class="searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search()">
            <el-form-item prop="keyword" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyword" maxlength="50" placeholder="档案题名/档号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="archivesBasicDataId" label="档案分类：">
                <SelectTree class="selectBox" ref="menuParentTree"
                            :props="defaultProps"
                            :data="classifyList"
                            :value="searchForm.archivesBasicDataId"
                            :clearable="true"
                            :accordion="true"
                            @getValue="(value) => {searchForm.archivesBasicDataId=value}"/>
            </el-form-item>
            <el-form-item prop="warehousingState" label="状态：" v-if="dataType == '0'">
                <el-select v-model="searchForm.warehousingState" clearable>
                    <el-option label="在库" value="在库"></el-option>
                    <el-option label="残损" value="残损"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" @click="advancedSearch()">高级检索</el-button>
                <el-button type="primary" @click="add()">确定</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 215px)"
                @selection-change="selectionChangeHandle"
                class="table" ref="multipleTable" :row-key='idKey'>
            <el-table-column type="selection" :reserve-selection='true'
                             :selectable="(row) => {return !selectedList.find((v) => v.archivesDataId == row.id);}"
                             fixed/>
            <el-table-column prop="archivesBasicDataName" label="档案分类" min-width="120" show-overflow-tooltip/>
            <el-table-column prop="archivesBasicDataType" label="档案类型" min-width="120" show-overflow-tooltip/>
            <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
            <el-table-column prop="warehousingState" label="状态" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="reference" label="档号" min-width="120"
                             show-overflow-tooltip></el-table-column>
            <el-table-column v-for="item in config" :key="item.archivesBasicId"
                             :prop="item.code"
                             :label="item.forName ? item.forName : item.basicName"
                             v-if="item.dataSelect == 0"
                             show-overflow-tooltip min-width="120">
                <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                                placement="top-start">
                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                    <span v-else v-html="scope.row.archivesData[item.code]"></span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" :width="listConfig.length ? '120': ''" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="view(scope.row, 'view')">查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="text_center m_t1">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[15, 30, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <RecordDetails ref="recordDetails"></RecordDetails>
        <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    </el-dialog>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    import RecordDetails from './recordDetails'
    import AdvancedSearch from '@/components/advancedSearch/advancedSearch'

    export default {
        components: {
            SelectTree, RecordDetails, AdvancedSearch
        },
        data() {
            return {
                visible: false,
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                classifyList: [],
                searchForm: {
                    archivesBasicDataId: null,
                    warehousingState: '',
                    keyword: '',
                    current: 1,
                    size: 15,
                    whereList: [],
                    fieldList: [],
                    fieldOrList: [],
                    fieldWhereList: [],
                    highlight: false,
                    transfer: true,
                    notArchivesBasicDataType: false,
                },
                config: [],
                listConfig: [],
                advancedList: [],
                dataList: [],
                dataListSelections: [],
                loading: false,
                total: 0,
                idKey: 'id',
                selectedList: [],
                selectedTotal: 0,
                dataType: null
            }
        },
        methods: {
            // list 已选数据
            // type 0电子，1阅览，2借出，3内部利用, 4移交
            // total 选择条数
            init(list, type, total) {
                this.dataType = type
                this.searchForm.fieldList = []
                this.searchForm.fieldOrList = []
                this.searchForm.fieldWhereList = []
                this.searchForm.warehousingState = ''
                this.searchForm.archivesBasicDataId = null
                this.searchForm.keyword = ''
                this.selectedList = list
                if (type == 4) {
                    this.selectedTotal = total
                } else {
                    this.getByBorrowType()
                }
                this.dataListSelections = []
                this.getClassifyList(type)
                this.visible = true
                this.$nextTick(() => {
                    this.search()
                })
            },
            // 查询
            search() {
                this.advancedList = []
                this.searchForm.whereList = []
                if (this.searchForm.archivesBasicDataId) {
                    this.getConfig()
                } else {
                    this.getAllData()
                }
            },
            // 获取借阅上限
            getByBorrowType() {
                this.$axios(this.api.record.getByBorrowType, {borrowType: this.dataType}).then(res => {
                    if (res.status) {
                        this.selectedTotal = res.data.maxNumber
                    }
                })
            },
            // 获取列表数据
            getList(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                // 电子借阅
                if (this.dataType == 0) {
                    this.searchForm.notArchivesBasicDataType = true
                    this.searchForm.fieldList = []
                    this.searchForm.fieldOrList = [
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "warehousingState",
                            "value": "在库"
                        },
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "warehousingState",
                            "value": "残损"
                        }
                    ]
                    if (this.searchForm.warehousingState) {
                        this.searchForm.fieldWhereList = [
                            {
                                "archivesBasicTypeMethodCode": "=",
                                "archivesBasicTypeCode": "text",
                                "key": "warehousingState",
                                "value": this.searchForm.warehousingState,
                                method: "and",
                            }
                        ]
                    } else {
                        this.searchForm.fieldWhereList = []
                    }
                }
                // 实体借阅
                if (this.dataType == 1 || this.dataType == 2 || this.dataType == 3) {
                    this.searchForm.fieldList = [
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "warehousingState",
                            "value": "在库"
                        },
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "borrowingState",
                            "value": "未借出"
                        }
                    ]
                    this.searchForm.fieldOrList = []
                    this.searchForm.fieldWhereList = []
                }
                // 移交
                if (this.dataType == 4) {
                    this.searchForm.transfer = false
                    this.searchForm.fieldList = [
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "warehousingState",
                            "value": "在库"
                        },
                        {
                            "archivesBasicTypeMethodCode": "=",
                            "archivesBasicTypeCode": "text",
                            "key": "borrowingState",
                            "value": "未借出"
                        }
                    ]
                    this.searchForm.fieldOrList = []
                    this.searchForm.fieldWhereList = []
                }
                if (this.searchForm.keyword) {
                    this.searchForm.fieldWhereList.push({
                            archivesBasicTypeMethodCode: "%",
                            archivesBasicTypeCode: "text",
                            key: "archivesDataName",
                            value: this.searchForm.keyword,
                            method: "or",
                        },
                        {
                            archivesBasicTypeMethodCode: "%",
                            archivesBasicTypeCode: "text",
                            key: "reference",
                            value: this.searchForm.keyword,
                            method: "or",
                        })
                }
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.keyword = ''
                this.$axios(this.api.record.queryDatabaseSearchPage, p, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                        this.loading = false
                    })
                })
            },
            // 全部分类元数据字段
            getAllData() {
                this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
                    if (res.status) {
                        this.config = res.data
                        this.getList(1)
                    }
                })
            },
            // 获取档案分类元数据字段
            getConfig() {
                this.$axios(this.api.record.basicdataGetById + '/' + this.searchForm.archivesBasicDataId).then(res => {
                    if (res.status) {
                        this.listConfig = []
                        this.config = res.data.archivesBasicDataGroup
                        this.config.map(item => {
                            if (item.dataSelect == 0) {
                                this.listConfig.push(item)
                            }
                        })
                        this.getList(1)
                    }
                })
            },
            // 高级检索
            advancedSearch() {
                this.$refs.advancedSearch.init(this.config, this.advancedList)
            },
            // 获取高级检索条件
            getSrhCondition(data) {
                if (data) {
                    this.advancedList = data.advancedList
                    this.searchForm.whereList = data.whereList
                    this.getList(1)
                }
            },
            // 确定
            add() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (this.selectedTotal && this.dataListSelections.length + this.selectedList.length > this.selectedTotal) {
                    this.$message.error('最多选择' + this.selectedTotal + '条档案')
                    return
                }
                this.$emit('getDataList', this.dataListSelections)
                this.visible = false
            },
            // 查看
            view(row, method) {
                this.$refs.recordDetails.init(row, method, row.archivesBasicDataId)
            },
            // 重置
            reset() {
                this.searchForm.archivesBasicDataId = null
                this.searchForm.keyword = ''
                this.$refs.searchForm.resetFields();
                this.search()
            },
            // 获取档案分类
            getClassifyList(type) {
                let p = {}
                if (type == 0) {
                    p.notArchivesBasicDataType = "案卷库"
                }
                this.$axios(this.api.record.selectArchivesBasicData, p).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.getList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.getList()
            },
        },
    }
</script>

<style scoped>

</style>
